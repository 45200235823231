export default function ABOUT_PART() {
    return (
        <section class="about-text">
            <p>
                I am a passionate and driven individual with a strong background in web development, graphic design, and software engineering. As a co-founder and team lead at Leetmorph Technology Pvt. Ltd., I strive to innovate and deliver creative solutions that meet client needs.
            </p>

            <p>
                My journey includes hands-on experience in building dynamic websites, designing user-friendly interfaces, and contributing to meaningful projects like the Rotaract Club of Baneshwor. I'm committed to continuous learning and personal growth, aiming to excel in both my technical career and future goals in public service.
            </p>
        </section>
    )
}
